// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicPrivacyPolicy-module--__wab_img-spacer--MLpcj";
export var column__awUa = "PlasmicPrivacyPolicy-module--column__awUa--Ah3ou";
export var column__iHfl = "PlasmicPrivacyPolicy-module--column__iHfl--vuVx1";
export var columns = "PlasmicPrivacyPolicy-module--columns--u11Hv";
export var container3 = "PlasmicPrivacyPolicy-module--container3--tScm1";
export var footer = "PlasmicPrivacyPolicy-module--footer--PYWxc";
export var freeBox__gkWzo = "PlasmicPrivacyPolicy-module--freeBox__gkWzo--d4d8g";
export var freeBox__i9N5N = "PlasmicPrivacyPolicy-module--freeBox__i9N5N--53bq0";
export var freeBox__ldkpB = "PlasmicPrivacyPolicy-module--freeBox__ldkpB--2VfzN";
export var freeBox__p6N1I = "PlasmicPrivacyPolicy-module--freeBox__p6N1I--IVcOa";
export var freeBox__y5PiI = "PlasmicPrivacyPolicy-module--freeBox__y5PiI--V3INn";
export var freeBox__zvIMn = "PlasmicPrivacyPolicy-module--freeBox__zvIMn--NL917";
export var h1 = "PlasmicPrivacyPolicy-module--h1--+Rx8U";
export var h4__jiwAu = "PlasmicPrivacyPolicy-module--h4__jiwAu--NMtkl";
export var h4__lMLeC = "PlasmicPrivacyPolicy-module--h4__lMLeC--nyrq7";
export var h4__tqueF = "PlasmicPrivacyPolicy-module--h4__tqueF--HUfyP";
export var h4__ymYlT = "PlasmicPrivacyPolicy-module--h4__ymYlT--YV2ml";
export var header = "PlasmicPrivacyPolicy-module--header--KIEBG";
export var img = "PlasmicPrivacyPolicy-module--img--f2WuG";
export var mainSection = "PlasmicPrivacyPolicy-module--mainSection--5V5xP";
export var pageBanner = "PlasmicPrivacyPolicy-module--pageBanner--VfvN-";
export var root = "PlasmicPrivacyPolicy-module--root--BAbWy";
export var text__ccshR = "PlasmicPrivacyPolicy-module--text__ccshR--gw1tb";
export var text__hMoMq = "PlasmicPrivacyPolicy-module--text__hMoMq--y-NFD";
export var text__i5R7Z = "PlasmicPrivacyPolicy-module--text__i5R7Z--7hb2A";
export var text__vrCnN = "PlasmicPrivacyPolicy-module--text__vrCnN--DsNee";
export var text__xEyAj = "PlasmicPrivacyPolicy-module--text__xEyAj--boRXn";